import { useRouter } from 'next/router'
import NextLink from 'next/link'
import { Box, Button } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import Image from 'next/legacy/image'
import logo from '../public/logo.png'

function IndexPage() {
    const { user } = useAuth0()
    const router = useRouter()
    // if we're logged in, move them to dashboard
    if (user) {
        router.push('/dashboard')
    }

    return (
        <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            flexDirection="column"
            height="100vh"
        >
            <Box position="relative" display="flex" justifyContent="center">
                <Image src={logo} alt="Belfry Logo" />
            </Box>

            <Box
                display="flex"
                alignContent="center"
                justifyContent="center"
                mt={2}
            >
                <NextLink passHref href="/dashboard" legacyBehavior>
                    <Button variant="contained" size="xlarge">
                        Log In
                    </Button>
                </NextLink>
            </Box>
        </Box>
    )
}
export default IndexPage
